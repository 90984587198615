// components/Testimonials.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Testimonials() {
  const testimonials = [
    { name: 'David Martino Co', rating: 4.8, date: '30 November 2021', content: 'Echo platform is a good platform.' },
    { name: 'Jake Harris Nyo', rating: 4.5, date: '29 November 2021', content: 'Echo platform helps me to help others.' },
    { name: 'May Catherina', rating: 4.7, date: '27 November 2021', content: 'I love using echo platform.' },
    { name: 'Random User', rating: 3.9, date: '24 November 2021', content: 'This platform is okay.' },
    { name: 'Mark Amber Do', rating: 4.3, date: '21 November 2021', content: 'It really helps in my daily tasks!' }
  ];

  return (
    <section id="testimonials" className="py-5 bg-light">
      <Container>
        <h2 className="text-center mb-5">Check What The Users Say About Us</h2>
        <Row>
          {testimonials.map((testimonial, index) => (
            <Col md={4} key={index}>
              <Card className="mb-4 shadow-sm">
                <Card.Body>
                  <Card.Text>"{testimonial.content}"</Card.Text>
                  <footer className="blockquote-footer">
                     <cite title="Review Date">{testimonial.date}</cite>
                  </footer>
                  <div className="text-center mt-2">
                    {Array.from({ length: 5 }, (_, i) => (
                      <span key={i} className={i < Math.round(testimonial.rating) ? 'text-warning' : 'text-muted'}>
                        &#9733;
                      </span>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Testimonials;
