// components/Header.js

import React from 'react';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
function Header() {
  return (
    <Navbar bg="light" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#home">
          <img
            src="/images/logo-no-background.png"
            alt="Echo"
            width="50"
            height="50"
            className="d-inline-block align-top"/>
          The echo platform</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#pricing">Pricing</Nav.Link>
            <Nav.Link href="#newsletter">Newsletter</Nav.Link>
          </Nav>
          <Link to="/AuthPage" className="me-2">
            <Button variant="outline-primary">Sign In</Button>
          </Link>
          <Link to="/Home" className="me-2">
            <Button variant="outline-primary">Home</Button>
          </Link>


        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header
