// components/Services.js
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';

function Services() {
  const services = [
    { title: ' Connect', description: "Step into a world where you're never alone Our chat feature connects you with others who have walked a similar path. Whether you're facing anxiety, stress, or just need someone to listen, you'll find a community that understands and supports you.", photo : "/images/service-icon-01.png"  },
    { title: ' Share', description: "Open up and share your story, your struggles, and your victories. In the Echo community, your voice matters. By sharing your experiences, you not only understand yourself but also help others realize they're not alone. It's a two-way street where giving and receiving support go hand in hand." , photo : "/images/service-icon-02.png" },
    { title: ' Support', description: "Sometimes, all it takes is a few kind words to make a world of difference. Offer your support to others who need it, and in return, find the comfort you seek. Our platform thrives on empathy and mutual aid, creating a network of support that grows stronger with every conversation." , photo : "/images/service-icon-03.png" },
    
  ];

  return (
    <section id="services" className="py-5">
      <Container>
        <h2 className="text-center mb-5">Amazing Services and features For You</h2>
        <p>Echo is all about building connections through powerful peer-to-peer chat features. Whether you are seeking advice,comfort,or just someone who understands,our annonymous chat that lets you engage in meaningful conversations with others who share similar experiences. It is a safe space where you can open up, offer support and receive it in return.Every conversation is a step towards feeling more  understood and less alone.</p>
        <Row>
          {services.map((service, index) => (
            <Col md={3} key={index}>
              <Card className="mb-4">
                <Card.Body>
                  <img src={service.photo} alt="Service" className="img-fluid mb-3" />
                  <Card.Title>{service.title}</Card.Title>
                  <Card.Text>{service.description}</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}

export default Services;