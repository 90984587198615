// components/About.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function About() {
  return (
    <section id="about" className="py-5 bg-light">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h2>About What We Do & Who We Are</h2>
            <p>Echo is a platform dedicated to fostering connections and providing support in a world where loneliness and mental health challenges are increasingly common. Our mission is to offer a space where everyone can speak openly about their feelings, share their experiences, and find solace in knowing they are not alone.

Our vision is to create a global community where people can support each other, regardless of background or circumstance. Whether you're dealing with stress, anxiety, or just need someone to talk to, Echo is here to listen.</p>
            
          </Col>
          <Col lg={6}>
            <img src="images/about-right-dec.png" alt="About Us" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default About;