import React, { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

function AuthPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post('http://localhost:8000/api/login/', { email, password });

      console.log('Login successful:', response.data);

    } catch (error) {
      console.error('Login failed:', error.response.data);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg flex overflow-hidden w-3/4 max-w-4xl justify-center">
        <div className="w-1/2 p-8">
          <h1 className="text-3xl font-bold text-center mb-8">Log in</h1>
          <button className="w-full py-2 px-4 bg-cyan-500 text-white rounded-lg hover:bg-cyan-600 mb-6">
            Sign in with Google
          </button>
          <p className="text-center mb-4 text-gray-600">Or sign in with email</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-gray-700">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300" 
                value={email}
                onChange={(e) => setEmail(e.target.value)} 
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-gray-700">Password:</label>
              <input 
                type="password" 
                id="password" 
                name="password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={password}
                onChange={(e) => setPassword(e.target.value)} 
                required
              />
            </div>
            <div className="text-right text-blue-500">
              <a href="/src/pages/AuthPage.js" className=" hover: underline">Don't remember your password?</a>
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Login
            </button>
          </form>
          <p className="mt-6 text-center text-gray-600">
            New user? 
            <Link to="/Signup" className="text-blue-500 hover:underline"> Sign up</Link>
          </p>
        </div>
        
      </div>
    </div>
  );
}

export default AuthPage;
