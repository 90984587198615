import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';

function PricingPlans() {
  return (
    <section id="pricing" className="py-5 mt-5">
      <Container>
        <h2 className="text-center mb-4">Pricing plans for all sizes</h2>
        <p className="text-center mb-5">Select an affordable plan that's packed with the best features to meet your needs.</p>

        
        <Row className="justify-content-center">
          
          <Col md={4} className="mb-4">
            <Card className="h-100 text-center shadow-sm">
              <Card.Body>
                <Card.Title>Daily</Card.Title>
                <Card.Text className="text-muted">
                  Ideal for Users who want to try the platform for a short period or need temporary access.
                </Card.Text>
                <h3>Ksh 20 <small>/Day</small></h3>
                <Button variant="primary" className="mt-3">Buy Plan</Button>
                <ul className="list-unstyled mt-3">
                  <li>✔ Full access to all platform features for 24 hours</li>
                  <li>✔ Ability to send unlimited anonymous messages</li>
                  <li>✔ 3 Penpal Recommendations</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          
          <Col md={4} className="mb-4">
            <Card className="h-100 text-center shadow-sm border-primary">
              <Card.Body>
                <Card.Title>Monthly</Card.Title>
                <Card.Text className="text-muted">
                  Ideal for Users who want ongoing support or expect regular platform use.
                </Card.Text>
                <h3>Ksh 250 <small>/Month</small></h3>
                <Button variant="primary" className="mt-3">Current Plan</Button>
                <ul className="list-unstyled mt-3">
                  <li>✔ Full access to all platform features for 24 hours</li>
                  <li>✔ Ability to send unlimited anonymous messages</li>
                  <li>✔ Unlimited Penpal Recommendations</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>

          
          <Col md={4} className="mb-4">
            <Card className="h-100 text-center shadow-sm">
              <Card.Body>
                <Card.Title>Annually</Card.Title>
                <Card.Text className="text-muted">
                  Ideal for Users dedicated to long-term mental health support.
                </Card.Text>
                <h3>Ksh 2560 <small>/Year</small></h3>
                <Button variant="primary" className="mt-3">Buy Plan</Button>
                <ul className="list-unstyled mt-3">
                  <li>✔ Full access to all platform features for 24 hours</li>
                  <li>✔ Ability to send unlimited anonymous messages</li>
                  <li>✔ Unlimited Penpal Recommendations</li>
                </ul>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default PricingPlans;
