import React from "react";  
import Header from "../components/Header";  
import Banner from "../components/Banner";
import Services from "../components/Services";
import About from "../components/About";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import 'bootstrap/dist/css/bootstrap.min.css';


const LandingPage = () => {

    return (
        <div className="App">
        <Header />
        <Banner />
        <Services />
        <About />
        <Testimonials />
        <Pricing />
        <Newsletter />
        <Footer />
      </div>
    );
}

export default LandingPage;