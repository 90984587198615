// components/Newsletter.js
import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

function Newsletter() {
  return (
    <section id="newsletter" className="py-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="text-center">
            <h2>Join our mailing list</h2>
            <p>Stay up to date with our latest news and products.</p>
            <Form className="mt-4">
              <Form.Group controlId="formBasicEmail">
                <Form.Control type="email" placeholder="Enter email" />
              </Form.Group>
              <Button variant="primary" type="submit" className="mt-3">
                Subscribe
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Newsletter;