// components/Footer.js
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

function Footer() {
  return (
    <footer className="bg-dark text-light py-4">
      <Container>
        <Row>
          <Col md={4}>
            <h5>Contact Us</h5>
            <p>61261-00200 Nairobi Kenya</p>
            <p>Email: info@echoplatform.com</p>
            <p>Phone: 0793823153 </p>
          </Col>
          <Col md={4}>
            <h5>Quick Links</h5>
            <ul className="list-unstyled">
              <li><a href="#home">Home</a></li>
              <li><a href="#services">Services</a></li>
              <li><a href="#about">About</a></li>
              <li><a href="#pricing">Pricing</a></li>
              <li><a href="#newsletter">Newsletter</a></li>
            </ul>
          </Col>
          <Col md={4}>
            <h5>Follow Us</h5>
            <p>Stay connected on social media</p>
            <div>
              <i className="fab fa-facebook-f me-3"></i>
              <i className="fab fa-twitter me-3"></i>
              <i className="fab fa-instagram me-3"></i>
              <i className="fab fa-linkedin-in"></i>
            </div>
          </Col>
        </Row>
        <hr />
        <p className="text-center">&copy; 2024 Echo Platform. All rights reserved.</p>
      </Container>
      <img src="/images/footer-bg.png" alt="Footer Wave" className="img-fluid" />
    </footer>
  );
}

export default Footer;