// components/Banner.js
import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';

function Banner() {
  return (
    <section id="home" className="py-5 mt-5">
      <Container>
        <Row className="align-items-center">
          <Col lg={6}>
            <h1> Find Connection in Silence</h1>
            <h1>Anonymous Conversations.</h1>
            <p>Discover a safe space where you can talk openly. <br/>Whether you're looking to share your story or simply listen. <br/>Echo connects you to a community that cares.</p>
            <Button variant="primary" className="me-2">
              <i className="fab fa-apple"></i> Start 30-Day free trial.  
            </Button>
            
          </Col>
          <Col lg={6}>
            <img src="images/slider-dec.png" alt="App" className="img-fluid" />
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Banner;