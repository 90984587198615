import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';

function Signup() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    termsAccepted: false
  });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage('Passwords do not match');
      return;
    }

    if (!formData.termsAccepted) {
      setErrorMessage('Please accept the terms and conditions');
      return;
    }

    try {
      const response = await axios.post('http://localhost:8000/api/signup/', {
        email: formData.email,
        password: formData.password
      });

      console.log('Signup successful:', response.data);
      navigate('/login'); // Redirect to login page upon success
    } catch (error) {
      console.error('Signup failed:', error.response?.data || error.message);
      setErrorMessage('Signup failed. Please try again.');
    }
  };

  const handleGoogleSignIn = () => {
    // Implement Google Sign-In logic here
    console.log('Google Sign-In clicked');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white shadow-lg rounded-lg flex justify-center overflow-hidden w-3/4 max-w-4xl">
        <div className="w-1/2 p-8">
          <h1 className="text-3xl font-bold text-center mb-8">Sign up</h1>
          <button
            onClick={handleGoogleSignIn}
            className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600 mb-6"
          >
            Sign in with Google
          </button>
          <p className="text-center mb-4 text-gray-600">Or sign up with email</p>
          <form onSubmit={handleSubmit} className="space-y-4">
            {errorMessage && <p className="text-red-500">{errorMessage}</p>}
            <div>
              <label htmlFor="email" className="block text-gray-700">Email:</label>
              <input 
                type="email" 
                id="email" 
                name="email"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300" 
                value={formData.email}
                onChange={handleChange} 
                required
              />
            </div>
            <div>
              <label htmlFor="password" className="block text-gray-700">Password:</label>
              <input 
                type="password" 
                id="password" 
                name="password"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={formData.password}
                onChange={handleChange} 
                required
              />
            </div>
            <div>
              <label htmlFor="confirmPassword" className="block text-gray-700">Confirm Password:</label>
              <input 
                type="password" 
                id="confirmPassword" 
                name="confirmPassword"
                className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring focus:border-blue-300"
                value={formData.confirmPassword}
                onChange={handleChange} 
                required
              />
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                id="termsAccepted"
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
                className="mr-2"
              />
              <label htmlFor="termsAccepted" className="text-sm text-gray-700">
                I agree to the terms and conditions
              </label>
            </div>
            <button type="submit" className="w-full py-2 px-4 bg-blue-500 text-white rounded-lg hover:bg-blue-600">
              Sign up
            </button>
          </form>
          <p className="mt-6 text-center text-gray-600">
            Already have an account? 
            <Link to="/AuthPage" className="text-blue-500 hover:underline"> Sign in</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Signup;