import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import AuthPage from './pages/AuthPage';
import Signup from './pages/Signup';
import EchoPlatform from './pages/Home';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/AuthPage" element={<AuthPage />} />
        <Route path="*" element={<h1>Not Found</h1>} />
        <Route path='/Signup' element={<Signup />} />
        <Route path="/Home" element={<EchoPlatform/>} />
      </Routes>
    </Router>
  );
};

export default App;
