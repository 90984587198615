import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';


const Home = () => (
  <header className=" h-5/6 bg-gradient-to-b from-sky-400 to-violet-700 text-white p-8">
    <div className=" mx-auto flex items-center justify-between">
      <div>
         <h1 className=" px-5 *:first-letter:text-5xl font-bold mb-2">Welcome to Echo Platform</h1>
        <p className="mb-4 text-xl w-2/3 text-center">Echo is all about building connections through our powerful peer-to-peer chat feature. Whether you're seeking advice, comfort, or just someone who understands, our anonymous chat lets you engage in meaningful conversations with others who share similar experiences. It's a safe space where you can open up, offer support, and receive it in return. Every conversation is a step towards feeling more understood and less alone.</p>
        <button className=" bg-yellow-600 text-black px-4 py-2 rounded">Creat an Account</button>
      </div>
      <div className="w-24 h-24 bg-indigo-400 rounded-full flex items-center justify-center">
        <div className="w-16 h-16 bg-indigo-200 rounded-full flex items-center justify-center">
          <div className="w-8 h-8 bg-indigo-600 rounded-full"></div>
        </div>
      </div>
    </div>
  </header>
);

const YouTube = () => (
  <div className="bg-black p-4 flex justify-center items-center">
    <iframe
      width="560"
      height="315"
      src="https://www.youtube.com/embed/dQw4w9WgXcQ"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      className="rounded-lg"
    ></iframe>
  </div>
);

const blogPosts = [
  {
    id: 1,
    title: "Getting Started with Echo Platform",
    description: "Learn the basics of Echo Platform and how to set up your first project.",
    photo: "/images/comp.webp"
  },
  {
    id: 2,
    title: "Advanced Echo Features",
    description: "Dive deep into Echo Platform's advanced features and boost your productivity.",
    photo: "/images/comp1.webp"
  },
  {
    id: 3,
    title: "Echo Platform Best Practices",
    description: "Discover the best practices for using Echo Platform in your development workflow.",
    photo: "/images/comp2.jpg"
  }
];

const BlogPost = ({ title, description, photo }) => (
  <div className="bg-white p-4 shadow-md rounded-lg">
    <img src={photo} alt="Blog post" className="w-full h-40 object-cover mb-4 rounded" />
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const Blog = () => (
  <section className="container mx-auto my-8">
    <h2 className="text-2xl font-bold mb-4">Our Blog</h2>
    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
      {blogPosts.map(post => (
        <BlogPost key={post.id} title={post.title} description={post.description} photo={post.photo} />
      ))}
    </div>
  </section>
);

const events = [
  {
    id: 1,
    title: "Echo Community Meetup",
    description: "Join us for our monthly community meetup to discuss the latest Echo Platform features and share experiences.",
    color: "white"
  },
  {
    id: 2,
    title: "Echo Developer Workshop",
    description: "A hands-on workshop for developers to learn advanced Echo Platform techniques and best practices.",
    color: "blue-600 text-white"
  },
  {
    id: 3,
    title: "Echo Platform Webinar",
    description: "An online session showcasing upcoming Echo Platform features and Q&A with the development team.",
    color: "green-500 text-white"
  },
  {
    id: 4,
    title: "Echo Hackathon",
    description: "A 24-hour coding event to build innovative projects using Echo Platform. Open to all skill levels!",
    color: "yellow-400"
  }
];

const EventCard = ({ title, description, color }) => (
  <div className={`bg-${color} p-4 rounded-lg shadow-md h-full`}>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p>{description}</p>
  </div>
);

const Events = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  const nextSlide = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prevIndex) => (prevIndex + 2 >= events.length ? 0 : prevIndex + 2));
    }
  };

  const prevSlide = () => {
    if (!isAnimating) {
      setIsAnimating(true);
      setCurrentIndex((prevIndex) => (prevIndex - 2 < 0 ? events.length - 2 : prevIndex - 2));
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
    }, 300);

    return () => clearTimeout(timer);
  }, [currentIndex]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, []);

  return (
    <section className="bg-lime-500 py-8">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-4">Upcoming Events</h2>
        <div className="relative">
          <div className="overflow-hidden">
            <div
              className={`flex transition-transform duration-300 ease-in-out ${
                isAnimating ? 'opacity-50' : 'opacity-100'
              }`}
              style={{ transform: `translateX(-${currentIndex * 50}%)` }}
            >
              {events.map((event) => (
                <div key={event.id} className="w-1/2 px-2 flex-shrink-0">
                  <EventCard
                    title={event.title}
                    description={event.description}
                    color={event.color}

                  />
                </div>
              ))}
            </div>
          </div>
          <button
            onClick={prevSlide}
            className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md mr-4"
          >
            <ChevronLeft size={24}/>
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md ml-4"
          >
            <ChevronRight size={24}/>
          </button>
        </div>
      </div>
    </section>
  );
};

const EchoPlatform = () => (
  <div className="min-h-screen bg-gray-100">
    <Home />
    <YouTube />
    <Blog />
    <Events />
  </div>
);

export default EchoPlatform;